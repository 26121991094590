import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Note variant="warning" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">Usage for this component is not encouraged</Text>
  <Text mdxType="Text">Instead, please see <Link as={GatsbyLink} to="/components/action-menu" mdxType="Link">action menu</Link>.</Text>
    </Note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      